// The purpose of this object is to run all the required javascripts against given DOM content, that
// would be consistent accross the application. It registeres two types of hooks, functional - that
// are to be executed once per content (e.g create selectize elements, initialize other libraries)
// and visuals that are to be executed every time new content is displayed (e.g. resize elements
// according to other element sizes).
//
// To register new hook, one must call Loader.functional(fn(content, resolve, reject)) or similar
// Loader.visual function. fn is to perform some action that calls resolve or reject when completed
// (similarly to regular promises).
//
// When adding dynamic content to the page, one need to run loader against it. The main goal is to
// ensure that all javascript has run before the content is displayed.
//
//    new Loader(myNewContent).all().then -> myNewContent.display()
//
// Note that visual hooks might require some extra setup in which it might be usefull to run those
// hooks separatly, e.g equalizer only works with element that are visible to browser (no parent
// being display: none):
//
//   loader = new Loader(myNewContent)
//   loader.functional()
//     .then (content) ->
//       content.style.setProperty('height', 0)
//       content.style.setProperty('display', 'block')
//       loader.visual()
//     .then (content) ->
//       slideDown(content)

import reportError from "@/javascript/components/errors"

export default class Loader {
  static functional (fn) {
    this.hooks.functional.push(fn)
  }

  static visual (fn) {
    this.hooks.visual.push(fn)
  }

  static promises = []

  static hooks = {
    functional: [],
    visual: []
  }

  constructor (content, loadingClass = 'loading') {
    this.content = content
    this.loadingClass = loadingClass
  }

  getHooks (modes) {
    const result = []
    Object.entries(this.constructor.hooks).forEach((entry) => {
      const [key, hooks] = entry
      if(modes.includes(key)) {
        result.push(...hooks)
      }
    })
    return result
  }

  start (opts = {}) {
    this.running = true
    if(this.loadingClass) {
      this.content.classList.add(this.loadingClass)
    }
    const hooks = this.getHooks(opts.modes)

    return Promise.all(
      hooks.map((hook) => {
        return ((hook) => {
          return Promise.race([
            new Promise((resolve, reject) => hook(this.content, resolve, reject)),
            new Promise((resolve) => {
              const func = () => {
                if(this.running) {
                  console.error('Loader timed out', hook)
                }
                resolve()
              }
              return setTimeout(func, opts.timeout || 2000)
            })
          ])
        })(hook)
      })
    )
    .catch((e) => reportError(e))
    .then(() => this.running = false)
    .then(() => {
      if(this.loadingClass) {
        this.content.classList.remove(this.loadingClass)
      }
    })
    .then(() => this.content)
  }

  all () {
    return this.start({modes: ['functional', 'visual']})
  }

  functional () {
    return this.start({modes: ['functional']})
  }
  visual () {
    return this.start({modes: ['visual']})
  }
}

document.addEventListener("turbo:before-stream-render", ((event) => {
  const fallbackToDefaultActions = event.detail.render

  event.detail.render = async function (streamElement) {
    fallbackToDefaultActions(streamElement)
    const target = document.getElementById(streamElement.target)
    if(target) {
      await new Loader(target).all()
    }
  }
}))

/* This is split into two methods because there are loading hooks that access
 * document, which means the new content needs to be present in the DOM.
 *
 * If this is changed then this code can be done in a single event listener as:
 *
 * document.addEventListener("turbo:before-frame-render", ((event) => {
 *   event.preventDefault();
 *
 *   new Loader(event.detail.newFrame).all()
 *     .then(() => {
 *       event.detail.resume();
 *     });
 * }))
 */
document.addEventListener("turbo:before-frame-render", ((event) => {
  event.detail.newFrame.childNodes.forEach(function(child) {
    child.classList.add('loading')
  })
}))

document.addEventListener("turbo:frame-render", ((event) => {
  event.target.childNodes.forEach(function(child) {
    new Loader(child).all()
  })
}))
